<script>
import { nextTick } from "vue";
import { ref } from "vue";
import { Swiper, SwiperSlide } from "swiper/swiper-vue.mjs";
import { Navigation } from "swiper/modules";
import axios from "axios";
import lottie from "lottie-web";
import "swiper/swiper-bundle.min.css";

export default {
    name: "Binding",
	data(){
		return{
			userInfo:{
				birthday: '',
				idNumber: '',
				character: 1,
				characterName: '',
				companyCode: ''
			},
			isLock: false
		}
	},
    computed: {
        userCathayInfo() {
            return this.$store.state.userCathayInfo;
        },
        userLineInfo() {
            return this.$store.state.userLineInfo;
        },
        hostUrl() {
            return this.$store.state.hostUrl;
        },
        liffID() {
            return this.$store.state.liffID;
        },
    },
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        const player = ref(1);
		const onSlideChange = (swiper) => {
			player.value=swiper.activeIndex+1
		};

        return {
            modules: [Navigation],
			onSlideChange,
			player,
        };
    },
	mounted() {
		nextTick(() => {
			let vm = this;
			vm.initLottie()
		});
	},
	beforeUnmount(){
		let vm = this;
		vm.destroyLottie()
	}, 
	methods:{
		checkInput(){
			// console.log("checkInput")
			let vm = this;
			
			if(vm.userInfo.characterName===''){
				vm.$swal({
					title: '格式錯誤',
					text: '請輸入玩家名字',
					confirmButtonText: '確定'
				})
				return
			}

			let validBirthday = /^\d{8}$/;
			if (!validBirthday.test(vm.userInfo.birthday.toString())) {
				vm.$swal({
					title: '格式錯誤',
					text: '請輸入8碼生日數字',
					confirmButtonText: '確定'
				})
				return ;
			}
			let validId = /^\d{5}$/;
			if (!validId.test(vm.userInfo.idNumber.toString())) {
				vm.$swal({
					title: '格式錯誤',
					text: '請輸入身分證(居留證)末五碼',
					confirmButtonText: '確定'
				})
				return ;
			}

			vm.userInfo.character=this.player.toString()
			vm.userInfo.companyCode=vm.userLineInfo.companyCode
			vm.sendBinding()
		},
		sendBinding(){
			// console.log("sendBinding")
			let vm = this;

			vm.isLock=true

			axios.post(vm.hostUrl+"api/bind",vm.userInfo,{
					headers: {
						"Authorization": `Bearer ${vm.userCathayInfo.accessToken}`,
						"Content-Type": "application/json",
					},
				})
				.then(function (response) {
					// console.log(response)
					if(response.status===200){
						vm.isLock=false
						location.href=`https://liff.line.me/${vm.liffID}`
						// vm.$swal({
						// 	title: "驗證成功",
						// 	html: "遊戲登錄成功 !<br />歡迎加入國泰健康管理為您打造的健康叢林冒險旅程，旅程開始時會推播通知您可以開始遊戲唷!",
						// 	confirmButtonText: '確定'
						// }).then(() => {
							
						// });
					}
				})
				.catch(function (error) {
					console.log(error.response);
					vm.isLock=false
					if(error.response.data.error==="901"){
						vm.$swal({
							title: '驗證失敗',
							text: '重複綁定',
							confirmButtonText: '確定'
						})
					}else if(error.response.data.error==="902"){
						vm.$swal({
							title: '驗證失敗',
							text: '資料有誤登錄失敗，請確認輸入的生日與末五碼是否正確；若有疑問請在LINE洽詢客服人員，謝謝您。',
							confirmButtonText: '確定'
						})
					}
				});
			
		},
		destroyLottie(){
			let vm = this;
			for(let i=0;i<=3;i++){
				vm[`aniPlayer${i+1}`].destroy()
			}
		},
		initLottie(){
			let vm = this;
			for(let i=0;i<=3;i++){
				vm[`aniPlayer${i+1}`] = lottie.loadAnimation({
					container: document.getElementById(`aniPlayer${i+1}`),
					animationData: require(`@/assets/json/player${i+1}.json`),
					renderer: "svg",
					loop: true,
					autoplay: true, 
					name: `aniPlayer${i+1}`, 
				});
			}
		}
	}
}
</script>
<template src="./template.pug" lang="pug"></template>
<style src="./style.sass" lang="sass" scoped></style>
<style>
/* swiper 樣式要用css寫法才能覆蓋 */
.swiper-button-prev::after,.swiper-button-next::after{
	content: none;
}
.swiper-button-prev,.swiper-button-next{
	display: block;
	width: 2.5em;
	height: 2.5em;
	background-size: contain;
	background-repeat: no-repeat;
	font-size: 20px;
	transition: .15s;
	transform: translateY(2em);
}
.swiper-button-prev{
	background-image: url("~@/assets/images/btn-prev.png");
	left: 1.7em;

}
.swiper-button-next{
	background-image: url("~@/assets/images/btn-next.png");
	right: 1.7em;
}
</style>